// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_9AQeVXdRzf053eg6p4", // fexpr
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/aEU8xp1x41p44AE5kZ", // fexpr

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Face Expression Detector 😃",
    HOME_PAGE_TITLE: "Home - Face Expression Detector 😃",
    DASHBOARD_PAGE_TITLE: "Home - Face Expression Detector 😃",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Face Expression Detector 😃",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to detect facial expressions, choose an image and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "face_expr_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/face-expr-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;